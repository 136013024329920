
import {computed, defineComponent, onMounted, onUnmounted} from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      getIllustrationsPath,
      currentLanguage
    };
  },
});
